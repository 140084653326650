import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor() {}

  set(key: string, value: any): any {
    localStorage.setItem(key, value);
  }

  get(key: string): any {
    return localStorage.getItem(key);
  }

  remove(key: string): any {
    return localStorage.removeItem(key);
  }

  setGlobalUsername(name: string): any {
    localStorage.setItem('globalUsername', name);
  }

  getGlobalUsername(): any {
    const globalUsername = localStorage.getItem('globalUsername');
    return globalUsername ? globalUsername : null;
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user) {
      const data = JSON.parse(user);
      return data;
    } else {
      return false;
    }
  }

  getToken() {
    const token = localStorage.getItem('token');
    if (token) {
      return token;
    } else {
      return false;
    }
  }
}
