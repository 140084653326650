import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '../@auth/guards/access.guard';
import { AlreadyLoggedInGuard } from '../@auth/guards/alreadyLoggedIn.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('../@auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AlreadyLoggedInGuard],
  },
  {
    path: 'doctor',
    loadChildren: () =>
      import('../@modules/@doctor/core/core.module').then(
        (m) => m.DoctorCoreModule
      ),
    canActivate: [AccessGuard],
    data: { role: 'doctor' },
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('../@modules/@patient/core/core.module').then(
        (m) => m.PatientCoreModule
      ),
  },
  {
    path: 'global',
    loadChildren: () =>
      import('../@modules/@globalLink/globalLink.module').then(
        (m) => m.GlobalLinkModule
      ),
  },
  {
    path: 'meet',
    loadChildren: () =>
      import('../@modules/meet/meet.module').then((m) => m.MeetModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadChildren: () =>
      import('../@modules/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
