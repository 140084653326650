export const environment = {
  production: false,
  CALL_DISCONNECT_TIMEOUT: 60000,
  CALL_RECONNECT_INTERVAL: 5000,
  CALL_RECONNECT_LIMIT: 12,

  LOGS: {
    ENABLED: false,
    DD_CLIENT_TOKEN: "",
    DD_SITE: "",
    DD_SERVICE: "",
    DD_FORWARD_ERRORS_TO_LOGS: true,
    DD_SAMPLE_RATE: 100,
  },

  // MAIN_LOGO:
  //   'https://praava-consult-api-prod.s3.ap-south-1.amazonaws.com/images/1637560547535%20-%20praava-logo.png',

  MAIN_LOGO:
    "https://imagedelivery.net/IEVmOmAlrv1BxorgilY5Og/be001afb-5069-42c9-ecaa-bb1e1e70b700/public",

  ACT_VIDEO_CONSULT_STARTED: "ACT_VIDEO_CONSULT_STARTED",
  ACT_VIDEO_CONSULT_ENDED: "ACT_VIDEO_CONSULT_ENDED",
  ACT_VIDEO_CONSULT_MED_PRESCRIBED: "ACT_VIDEO_CONSULT_MED_PRESCRIBED",
  ACT_VIDEO_CONSULT_LAB_PRESCRIBED: "ACT_VIDEO_CONSULT_LAB_PRESCRIBED",
  ACT_VIDEO_CONSULT_DOC_JOINED: "ACT_VIDEO_CONSULT_DOC_JOINED",

  // API_ENDPOINT: "http://localhost:3001/api/v1/",
  // SOCKET_ENDPOINT: "http://localhost:3001/init",
  // GLOBAL_LINK_SOCKET_ENDPOINT: "http://localhost:3001/globalLink",
  // MEET_SOCKET_ENDPOINT: "http://localhost:3001/meet",
  // GLOBAL_ENDPOINT: "http://localhost:3000/global/",
  // CONVERSATION_ENDPOINT: "http://localhost:3000/patient/conversation/",

  API_ENDPOINT: "https://api.talk2doc.xyz/api/v1/",
  SOCKET_ENDPOINT: "https://api.talk2doc.xyz/init",
  GLOBAL_LINK_SOCKET_ENDPOINT: "https://api.talk2doc.xyz/globalLink",
  MEET_SOCKET_ENDPOINT: "https://api.talk2doc.xyz/meet",
  GLOBAL_ENDPOINT: "https://talk2doc.xyz/global/",
  CONVERSATION_ENDPOINT: "https://talk2doc.xyz/patient/conversation/",

  APPOINTMENT_API_ENDPOINT: "",
  NEBULA_ENDPOINT: "",

  VAPID_PUBLIC_KEY:
    "BNy-OceIhtzSvXxnBw0hPsC2scAf_SyjK9F2U9C1mv_Bjgo36vBw2WKwwHa4hId5Ziy1wpGl3ycrpGNkfI4sKeM",

  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDAHw8IlvfOifMQNSPZQRJZoz-yEuwpKKI",
    authDomain: "project-bike.firebaseapp.com",
    databaseURL: "https://project-bike.firebaseio.com",
    projectId: "project-bike",
    storageBucket: "project-bike.appspot.com",
    messagingSenderId: "962024703435",
    appId: "1:962024703435:web:97561800c8a39dca2b624e",
  },

  ICE_SERVERS: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
    {
      urls: "turn:3.109.161.170:3478",
      credential: "QMwsSbJcu65Uv25",
      username: "pvturn",
    },
  ],
};
