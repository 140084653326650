import { StorageService } from './../../@services/storage.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AccessGuard implements CanActivate {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const role = route.data.role;
    const userType = this.storageService.get('userType') || 'doctor';

    if (this.storageService.getToken()) {
      if (userType === role) {
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

// canActivate(route: ActivatedRouteSnapshot) {
//     const role = route.data.role;
//     const userType = this.storageService.get('userType') || 'doctor';

//     if (this.storageService.getToken()) {
//       if (userType === role) {
//         return true;
//       } else {
//         this.router.navigate(['/']);
//         return false;
//       }
//     } else {
//       this.router.navigate(['/']);
//       return false;
//     }
// }
