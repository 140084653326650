import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from './../../@services/storage.service';

@Injectable()
export class AlreadyLoggedInGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  canActivate() {
    try {
      const data = this.storageService.getUser();
      const userType = this.storageService.get('userType') || 'doctor';

      if (data) {
        this.router.navigate([`/${userType}`]);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      return true;
    }
  }
}
